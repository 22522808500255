<template>
  <div>
    <el-card style="min-height: 45rem">
      <div class="flex-def flex-zCenter flex-cCenter flex-zTopBottom">
        <el-form label-position="left" style="width: 40rem">
          <div style="width: 100%;padding: 1rem 0 2rem;font-weight: 600">新合伙人规则配置</div>
          <el-form-item label="股东分红">
            <el-switch v-model="form.shareholder_enable"></el-switch>
          </el-form-item>
          <el-divider>店长奖励</el-divider>
          <el-form-item label="邀请店长奖励">
            直推
            <el-input-number v-model="form.shopowner_invite_direct_shopowner"></el-input-number> %
            间推
            <el-input-number v-model="form.shopowner_invite_indirect_shopowner"></el-input-number> %
          </el-form-item>
          <el-form-item label="平级奖励">
            <el-input-number v-model="form.shopowner_equal_award"></el-input-number> %
          </el-form-item>
          <el-divider>合伙人奖励</el-divider>
          <el-form-item label="合伙人邀请奖励">
            直推店长
            <el-input-number v-model="form.partner_invite_direct_shopowner"></el-input-number> %
            间推店长
            <el-input-number v-model="form.partner_invite_indirect_shopowner"></el-input-number> %
          </el-form-item>
          <el-form-item label="平级奖励">
            <el-input-number v-model="form.partner_equal_award"></el-input-number> %
          </el-form-item>
          <el-form-item align="right">
            <el-button @click="edit" type="primary">保存</el-button>
          </el-form-item>
        </el-form>
      </div>
    </el-card>
  </div>
</template>

<script>

export default {
  name: "conf",
  components:{
  },
  data() {
    return {
      form:{
        shopowner_invite_direct_shopowner:0,
        shopowner_invite_indirect_shopowner:0,
        shopowner_equal_award:0,
        partner_invite_direct_shopowner:0,
        partner_invite_indirect_shopowner:0,
        partner_equal_award:0,
        shareholder_enable:0,
      }
    }
  },
  mounted() {
    this.load();
  },
  methods: {
    load() {
      this.$u.api.shop.plugin.partnership.conf().then(res=>{
        this.form = res;
      })
    },
    edit(){
      this.$u.api.shop.plugin.partnership.confEdit(this.form).then(()=>{
        this.$message.success("操作成功");
        this.load();
      })
    },
  }
}
</script>

<style scoped>

</style>